<template>
    <div v-if="loader">
        Loading...
    </div>
    <div v-if="! loader">
        <CourierGlobalHeader :global_err="global_err" />
        <div v-if="global_err == ''">
            <div class="singup_comman_banner">
                <div class="comman_banner">
                    <div class="container">
                        <div class="banner_logo">
                            <img src="images/simpo_logo.svg" alt="Simpo" title="Simpo">
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="singup_page">
                        <div class="singup_title">
                            <h4>כניסה</h4>
                        </div>
                        <form @submit="login" class="singup_form" >
                            <p class="error_text" >{{errs}}</p>
                            <div class="form-group">
                                <label>טלפון</label>
                                <input type="text" class="form-control"  v-model="phone">
                            </div>
                            <div class="form-group">
                                <label>סיסמא</label>
                                <input type="password" class="form-control" v-model="password">
                            </div>
                            <div class="form-group">
                                <input type="submit" class="comman_anchor" value="כניסה">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackendModel from "../../models/BackendModel";
import CourierGlobalHeader from "./CourierGlobalHeader";
import('./courier/css/style.css')
export default {
  name: 'Courier',
  components: {
    CourierGlobalHeader
  },
  data() {
    return {
      loader: false,
      phone : '',
      password: '',
      errs:'',
      global_err : ''
    }
  },
    async mounted () {
    const backendModel = new BackendModel()
    this.loader = true
    let courier_session_id = backendModel.getCourierSessionId()
    let authRes = await backendModel.courier_Request('/Api/service/backoffice/courier_auth', {courier_session_id})
    if (typeof authRes == 'undefined') {
      this.global_err = 'Backend is down?'
    } else if (authRes.data.res == 'ERROR') {
      this.global_err = authRes.data.e
    } else if (authRes.data.res == 'OK') {
      await this.$router.push({ path: '/courier/courier_home' })
    }
    this.loader = false  
     this.errs = ''
     
   },
    methods : {
        async login(e) {
             e.preventDefault()
             const backendModel = new BackendModel()
             let res = await backendModel.courier_Request('/Api/service/backoffice/courier_login', {"phone" : this.phone, "password" : this.password});
             if (res.data.res == "NOK" && res.data.err == "less_data" ){
                this.errs = 'אופס, שכחת משהו'
            }
            if(res.data.res == "NOK"){
                this.errs = 'שגיאה'
            }
             if(res.data.res == "OK"){
                 let session_id = res.data.session_id
                  let CookieDate = new Date
                  CookieDate.setFullYear(CookieDate.getFullYear() + 10)
                  document.cookie = 'courier_session_id =' + session_id +'; expires=' + CookieDate.toUTCString() + ';'
                await this.$router.push({ path: '/courier/courier_home' })
                this.loader_prop = false
            }
        },
    }
}
</script>

<style>
@import 'courier/css/style.css';
</style>
